import BaseBean from "@/utils/BaseBean";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
import TtOaCard from "@/views/project/order/finance/tt/ttOa/TtOaCard.vue";

export interface ICustomerCardDataObj {
    utilInst:CustomerCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class CustomerCardUtil extends BaseBean{
    public dataObj:ICustomerCardDataObj;

    constructor(proxy:any,dataObj:ICustomerCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});
    }

    /**
     * 给num前面补0，一直让num达到n位数
     * @param num： 被操作数
     * @param n： 固定的总位数
     */
    public prefixZero(num:number, n:number):string{
        return (Array(n).join('0') + num).slice(-n);
    }
    //变动历史
    public changeHis(changeList:Array<any>,accountType:string,bz:string):void{
        this.dataObj.otherParams.amountInfo.changeList=changeList;
        this.dataObj.otherParams.amountInfo.changeList.sort((item1:any,item2:any)=>item1.time.localeCompare(item2.time));
        this.dataObj.otherParams.amountInfo.dialogVisible=true;
        this.dataObj.otherParams.amountInfo.accountType=accountType;
        this.dataObj.otherParams.amountInfo.bz=bz;
    }
    //信用证支付明细
    public async payDetail(item:any):Promise<any>{
        let modelComp;
        switch (this.dataObj.otherParams.amountInfo.accountType) {
            case "oa":modelComp=OaApplyCard;break;
            case "tt"://tt里面包含tt支付和信保回款两种情况
                if(item.type==1)modelComp=TtPayCard;
                else if(item.type==4)modelComp=TtOaCard;
                break;
        }
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:item.billId,modelComp:modelComp,ownerComp:this.proxy,cardFrom:'customerCard',
            title:'支付详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
}